import * as React from "react"
import { Link, HeadFC } from "gatsby"
import Seo from "../components/Seo"

const title = "Page Not Found"

const NotFoundPage = () => {
  return (
    <main>
      <h1>Page not found</h1>
      <p>
        You hit another wall to see if it was fake. It wasn't :(.
        <br />
        Might as well <Link to="/">return to home</Link>.
      </p>
    </main>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => <Seo title={title} />
